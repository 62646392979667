import type { ColumnType } from "antd/lib/table/interface";
import type { ReportLog } from "@/api/report-logs/types";
import { ReportLogStatus } from "@/api/report-logs/types";
import { TagFilter } from "@/components/common/TagFilter";
import { Color, DATETIME_FORMATTER } from "@/utils";
import { Tag } from "@/components/common/Tag/Tag";
import { ReportTag } from "@/components/Reports/ReportTag";
import { DownloadSpreadsheetButton } from "./DownloadSpreadsheetButton";
import { isNil } from "ramda";
import { BaseTag } from "@/components/common/BaseTag/BaseTag";

type ReportColumnKey = "Status" | "Date" | "Report" | "Variation" | "EmailAlert" | "Actions";

export const ReportLogColumn: Record<ReportColumnKey, ColumnType<ReportLog>> = {
  Status: {
    title: "Status",
    dataIndex: "status",
    render: (_, log) => (
      <TagFilter filters={{ status: log.status }}>
        <Tag color={log.status === ReportLogStatus.Sent ? Color.Success : Color.Failed}>{log.status}</Tag>
      </TagFilter>
    ),
  },
  Date: {
    title: "Date",
    dataIndex: "createdAt",
    sorter: true,
    render: (_, log) => DATETIME_FORMATTER.format(new Date(log.createdAt)),
  },
  Report: {
    title: "Report",
    dataIndex: ["report", "id"],
    render: (_, log) =>
      log.report ? (
        <TagFilter filters={{ reportId: log.report.id }}>
          <ReportTag report={log.report} />
        </TagFilter>
      ) : (
        "-"
      ),
  },
  EmailAlert: {
    title: "Email Alert",
    dataIndex: ["emailAlert", "id"],
    render: (_, log) =>
      log.emailAlert ? (
        <TagFilter filters={{ emailAlertId: log.emailAlert.id }}>
          <BaseTag>{log.emailAlert.name}</BaseTag>
        </TagFilter>
      ) : (
        "-"
      ),
  },
  Variation: {
    title: "Variation",
    key: "variation",
    render: (_, log) => {
      if (!isNil(log.variation.index)) {
        return log.variation.name ?? `Variation #${log.variation?.index + 1}`;
      }
      return "-";
    },
  },
  Actions: {
    key: "actions",
    render: (_, log) => log.spreadsheet.s3ObjectId && <DownloadSpreadsheetButton log={log} />,
  },
};
