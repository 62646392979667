import { Col, Form, Row } from "antd";
import { SalesforceQueryBuilder } from "./SalesforceQueryBuilder";
import { Field } from "../Form/Field";
import { useFieldPath } from "../Form/hooks";
import { requiredIfNotDraft } from "@/utils/validation";
import { SalesforceObjectPicker } from "@/components/SalesforceObjects/SalesforceObjectPicker";
import { OrderByList } from "@/components/common/SalesforceQuery/OrderByList";
import { SalesforceObjectPathPicker } from "@/components/common/ObjectPathPicker";
import { OpenplayBasedFilter } from "@/components/common/SalesforceQuery/OpenplayBasedFilter";

type FromFieldProps = {
  disabled?: boolean;
  tooltip?: string;
};

type Props = {
  fromFieldProps?: FromFieldProps;
};

export const SalesforceQueryForm = ({ fromFieldProps }: Props) => {
  const form = Form.useFormInstance();

  const { getAbsolutePath } = useFieldPath();

  const from = Form.useWatch(getAbsolutePath("from")) ?? null;

  const handleFromChange = () => {
    form.setFieldValue(getAbsolutePath("where"), null);
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Field
          label="From"
          name="from"
          rules={[{ required: true, message: "Field is mandatory" }]}
          tooltip={fromFieldProps?.tooltip}
        >
          <SalesforceObjectPicker onChange={handleFromChange} disabled={fromFieldProps?.disabled} />
        </Field>
      </Col>
      {from && (
        <>
          <Col span={24}>
            <Field
              key={from}
              label="Filter"
              name="where"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                (form) => ({
                  ...requiredIfNotDraft(form),
                  message: "At least one of the rules should be configured",
                }),
              ]}
            >
              <SalesforceQueryBuilder from={from} />
            </Field>
          </Col>
          <Col span={24}>
            <Field label="Order By" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <OrderByList sourceObject={from} />
            </Field>
          </Col>
          <Col span={12}>
            <Field label="Unique By" name="uniqueBy">
              <SalesforceObjectPathPicker obj={from} />
            </Field>
          </Col>
          <Col span={24}>
            <Field
              label="Filter by OpenPLay Query Results"
              name="openplayBasedFilter"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <OpenplayBasedFilter sourceObject={from} />
            </Field>
          </Col>
        </>
      )}
    </Row>
  );
};
