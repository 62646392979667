import { PageHeader } from "antd";
import { ReportLogTable } from "@/components/ReportLogs/ReportLogTable";
import { useParams } from "react-router-dom";
import { Summary } from "@/components/common/Summary/Summary";
import { useReport } from "@/api/reports/hooks";
import { ReportLogParentType } from "@/api/report-logs/types";

export const ReportLogsPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data: report } = useReport(id);
  return (
    <>
      <PageHeader
        title={<h1>Report Logs</h1>}
        extra={<Summary items={[{ label: "Report", value: report?.name }]} />}
      />
      <ReportLogTable parentType={ReportLogParentType.Report} reportId={id} />
    </>
  );
};
