import { PageHeader } from "antd";
import { ReportLogTable } from "@/components/ReportLogs/ReportLogTable";
import { useParams } from "react-router-dom";
import { Summary } from "@/components/common/Summary/Summary";
import { ReportLogParentType } from "@/api/report-logs/types";
import { useEmailAlert } from "@/api/email-alerts/hooks";

export const EmailAlertLogsPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data: alert } = useEmailAlert(id);
  return (
    <>
      <PageHeader
        title={<h1>Email Alert Logs</h1>}
        extra={<Summary items={[{ label: "Email Alert", value: alert?.name }]} />}
      />
      <ReportLogTable parentType={ReportLogParentType.EmailAlert} emailAlertId={id} />
    </>
  );
};
