import type { Report } from "@/api/reports/types";
import type { Pagination } from "@/api/types";
import type { EmailAlert } from "@/api/email-alerts/types";

export type Variation = {
  index?: number;
  name?: string;
};

export type Correspondent =
  | {
      name: string;
      address: string;
    }
  | string;

export type EmailMessage = {
  from?: Correspondent;
  to: Correspondent[];
  cc: Correspondent[];
  bcc: Correspondent[];
  subject?: string;
  body?: string;
};

export type Spreadsheet = {
  filename?: string;
  s3ObjectId?: string;
};

export enum ReportLogStatus {
  Sent = "sent",
  Failed = "failed",
}

export enum ReportLogParentType {
  Report = "report",
  EmailAlert = "email-alert",
}

export class ReportLog {
  id: string;
  parentType: ReportLogParentType;
  createdAt: string;
  status: ReportLogStatus;
  reason?: string;
  report?: Pick<Report, "id" | "name" | "status">;
  variation?: Variation;
  emailAlert?: Pick<EmailAlert, "id" | "name">;
  email: EmailMessage;
  spreadsheet: Spreadsheet;
}

export type ReportLogFilters = Pagination & {
  reportId?: string;
  status?: ReportLogStatus;
  startDate?: string;
  endDate?: string;
  parentType?: ReportLogParentType;
  emailAlertId?: string;
};
