import { PageHeader } from "antd";
import { ReportLogTable } from "../../ReportLogs/ReportLogTable";
import { ReportLogParentType } from "@/api/report-logs/types";

export const AllAlertsLogsPage = () => (
  <>
    <PageHeader title={<h1>Email Alert Logs</h1>} />
    <ReportLogTable parentType={ReportLogParentType.EmailAlert} />
  </>
);
