import { PageHeader } from "antd";
import { ReportLogTable } from "../../ReportLogs/ReportLogTable";
import { ReportLogParentType } from "@/api/report-logs/types";

export const AllReportsLogsPage = () => (
  <>
    <PageHeader title={<h1>Report Logs</h1>} />
    <ReportLogTable parentType={ReportLogParentType.Report} />
  </>
);
