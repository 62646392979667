import { Button, Col, Form, Row } from "antd";
import Icon, { ArrowRightOutlined } from "@ant-design/icons";
import { Plus, Remove } from "@/components/common/Icons";
import { IconButton } from "@/components/common/IconButton/IconButton";
import { Field } from "@/components/common/Form/Field";
import { SalesforceObjectPathPicker } from "@/components/common/ObjectPathPicker";
import { OpenplayQueryPicker } from "@/components/Reports/Details/OpenplayQueryPicker";
import { useFieldPath } from "@/components/common/Form/hooks";
import type { ReportOpenplayQuery } from "@/api/reports/types";
import { getEmptyArray } from "@/utils/empty";
import { useOpenplayQuery } from "@/api/openplay-queries/hooks";
import { OpenplayQueryFieldPicker } from "@/components/OpenplayQueries/OpenplayQueryFieldPicker";
import { FieldGroup } from "@/components/common/Form/FieldGroup";
import "./OpenplayBasedFilter.less";

type ValueType = {
  field: string[];
  openplay: {
    query: string;
    field: string[];
  };
} | null;

type Props = {
  sourceObject: string;
  value?: ValueType;
  onChange?: (value: ValueType) => void;
};

export const OpenplayBasedFilter = ({ value, onChange, sourceObject }: Props) => {
  const { getAbsolutePath } = useFieldPath();
  const form = Form.useFormInstance();

  const reportOpenplayQueryId = Form.useWatch<string>(
    getAbsolutePath(["openplayBasedFilter", "openplay", "query"])
  );
  const reportOpenplayQueries = Form.useWatch<ReportOpenplayQuery[]>("openplayQueries") ?? getEmptyArray();
  const openplayQueryId = reportOpenplayQueries.find((query) => query.id === reportOpenplayQueryId)
    ?.openplayQuery?.id;
  const { data: openplayQuery } = useOpenplayQuery(openplayQueryId);

  const handleAddFilter = () => {
    onChange({
      field: null,
      openplay: {
        query: null,
        field: null,
      },
    });
  };

  const handleRemoveFilter = () => {
    onChange(null);
  };

  const handleOpenplayQueryChange = () => {
    form.setFieldValue(getAbsolutePath(["openplayBasedFilter", "openplay", "field"]), void 0);
  };

  if (!value) {
    return (
      <Button icon={<Icon component={Plus} />} size="small" onClick={handleAddFilter}>
        Add Filter
      </Button>
    );
  }

  return (
    <FieldGroup prefix="openplayBasedFilter">
      <div className="openplay-based-filter">
        <Row gutter={[8, 8]} className="openplay-based-filter__body">
          <Col span={7}>
            <Field name="field" rules={[{ required: true, message: "Please, select Salesforce field" }]}>
              <SalesforceObjectPathPicker
                placeholder="Salesforce Field"
                obj={sourceObject}
                excludeFieldTypes={["multipicklist", "date", "datetime", "address", "boolean"]}
              />
            </Field>
          </Col>
          <Col span={1} className="openplay-based-filter__arrow">
            <ArrowRightOutlined />
          </Col>
          <Col span={8}>
            <Field
              name={["openplay", "query"]}
              rules={[{ required: true, message: "Please, select OpenPlay query" }]}
            >
              <OpenplayQueryPicker placeholder="OpenPlay Query" onChange={handleOpenplayQueryChange} />
            </Field>
          </Col>
          {openplayQuery && (
            <Col span={8}>
              <Field
                name={["openplay", "field"]}
                rules={[{ required: true, message: "Please, select OpenPlay field" }]}
              >
                <OpenplayQueryFieldPicker placeholder="OpenPlay Field" query={openplayQuery} />
              </Field>
            </Col>
          )}
        </Row>
        <IconButton className="openplay-based-filter__remove" icon={Remove} onClick={handleRemoveFilter} />
      </div>
    </FieldGroup>
  );
};
